export const API_CONFIG = {
  URL_MM: process.env.REACT_APP_API_URL_MM || 'https://menu-staging.winendine.com/v1',
  URL_WND: process.env.REACT_APP_API_URL_WND || 'https://api-staging.winendine.com/v1',
  TOKEN: process.env.REACT_APP_API_TOKEN || ''
};

export const ORDERING_API_CONFIG = {
  URL: process.env.REACT_APP_ORDERING_API_URL || 'https://pay-staging.winendine.com',
  TOKEN: process.env.REACT_APP_ORDERING_API_TOKEN || 'Wdfg444sdhf#wejnf$4rlfkdN'
};

export const CART_STORAGE_PREFIX = '__cart_';

export const GOOGLE_ANALYTICS_KEY = process.env.REACT_APP_GOOGLE_ANALYTICS_KEY || '';

export const EASY_TAB_URL = process.env.REACT_APP_EASY_TAB_URL || 'https://easytab-staging.drinkeasy.us/';

export const TYPE_FORM_BASE_URL: string = 'https://form.typeform.com/to/';

export const REACT_APP_STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '';

export const REACT_APP_LAUNCHDARKLEY_CLIENT_SIDE_ID = process.env.REACT_APP_LAUNCHDARKLEY_CLIENT_SIDE_ID || '';

export const REACT_APP_SDK_KEY = process.env.REACT_APP_SDK_KEY || '';
