import React, { lazy } from 'react';
import { MENU_TYPES } from '../utils/constants';

const Home = lazy(() => import('./Home'));
const RestaurantMenu = lazy(() => import('./RestaurantMenu'));
const Cart = lazy(() => import('./Cart'));
const OrderDetails = lazy(() => import('./OrderDetails'));

export const ROUTES = [
  {
    path: '/',
    component: Home,
    key: 'home',
    exact: true
  },
  {
    path: '/resy',
    component: Home,
    key: 'resy',
    exact: true
  },
  {
    path: '/cart/:restaurant',
    component: Cart,
    key: 'cart',
    exact: true
  },
  {
    path: '/:restaurant',
    component: RestaurantMenu,
    key: MENU_TYPES.DEFAULT,
    exact: true
  },
  {
    path: '/:restaurant/menu-official',
    component: RestaurantMenu,
    key: MENU_TYPES.OFFICIAL,
    exact: true
  },
  {
    path: '/:restaurant/menu-standard',
    component: RestaurantMenu,
    key: MENU_TYPES.STANDARD,
    exact: true
  },
  {
    path: '/:restaurant/menu-visual',
    component: RestaurantMenu,
    key: MENU_TYPES.VISUAL,
    exact: true
  },
  {
    path: '/:restaurant/menu-ordering',
    component: RestaurantMenu,
    key: MENU_TYPES.ORDERING,
    exact: true
  },
  {
    path: '/:restaurant/menu',
    component: RestaurantMenu,
    key: MENU_TYPES.DEFAULT,
    exact: true
  },
  {
    path: '/:restaurant/menu-ird',
    component: RestaurantMenu,
    key: MENU_TYPES.ORDERING,
    exact: true,
    ird: true
  },
  {
    path: '/:restaurant/menu-ivo',
    component: RestaurantMenu,
    key: MENU_TYPES.ORDERING,
    exact: true,
    ivo: true
  },
  {
    path: '/:restaurant/menu-ivo-to',
    component: RestaurantMenu,
    key: MENU_TYPES.ORDERING,
    exact: true,
    ivoTo: true
  },
  {
    path: '/:restaurant/menu-catering',
    component: RestaurantMenu,
    key: MENU_TYPES.ORDERING,
    exact: true,
    catering: true
  },
  {
    path: '/:restaurant/menu-official-qr',
    component: RestaurantMenu,
    key: MENU_TYPES.OFFICIAL,
    exact: true,
    qr: true
  },
  {
    path: '/:restaurant/menu-standard-qr',
    component: RestaurantMenu,
    key: MENU_TYPES.STANDARD,
    exact: true,
    qr: true
  },
  {
    path: '/:restaurant/menu-visual-qr',
    component: RestaurantMenu,
    key: MENU_TYPES.VISUAL,
    exact: true,
    qr: true
  },
  {
    path: '/:restaurant/menu-qr',
    component: RestaurantMenu,
    key: MENU_TYPES.DEFAULT,
    exact: true,
    qr: true
  },
  {
    path: '/:restaurant/menu-official-qr/:id',
    component: RestaurantMenu,
    key: MENU_TYPES.OFFICIAL,
    exact: true,
    qr: true
  },
  {
    path: '/:restaurant/menu-standard-qr/:id',
    component: RestaurantMenu,
    key: MENU_TYPES.STANDARD,
    exact: true,
    qr: true
  },
  {
    path: '/:restaurant/menu-visual-qr/:id',
    component: RestaurantMenu,
    key: MENU_TYPES.VISUAL,
    exact: true,
    qr: true
  },
  {
    path: '/:restaurant/menu-qr/:id',
    component: RestaurantMenu,
    key: MENU_TYPES.DEFAULT,
    exact: true,
    qr: true
  },
  {
    path: '/:restaurant/menu-official/:id',
    component: RestaurantMenu,
    key: MENU_TYPES.OFFICIAL,
    exact: true
  },
  {
    path: '/:restaurant/menu-standard/:id',
    component: RestaurantMenu,
    key: MENU_TYPES.STANDARD,
    exact: true
  },
  {
    path: '/:restaurant/menu-visual/:id',
    component: RestaurantMenu,
    key: MENU_TYPES.VISUAL,
    exact: true
  },
  {
    path: '/:restaurant/menu/:id',
    component: RestaurantMenu,
    key: MENU_TYPES.DEFAULT,
    exact: true
  },
  {
    path: '/order/:id',
    component: OrderDetails,
    key: 'order-details',
    exact: true
  },
  {
    path: '/order/:id/ird',
    component: OrderDetails,
    key: 'order-details',
    exact: true,
    ird: true
  },
  {
    path: '/order/:id/ivo',
    component: OrderDetails,
    key: 'order-details',
    exact: true,
    ivo: true
  },
  {
    path: '/order/:id/ivo-to',
    component: OrderDetails,
    key: 'order-details',
    exact: true,
    ivoTo: true
  },
  {
    path: '/order/:id/catering',
    component: OrderDetails,
    key: 'order-details',
    exact: true,
    catering: true
  },
  {
    path: '*',
    component: () => <div>Not found</div>,
    key: 'not-found'
  }
];
