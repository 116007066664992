import axios from 'axios';
import { API_CONFIG, ORDERING_API_CONFIG } from '../../../config';

const token = API_CONFIG.TOKEN;

export const apiRequest = axios.create({
  baseURL: ORDERING_API_CONFIG.URL,
  headers: {
    lang: 'en',
    token: ORDERING_API_CONFIG.TOKEN
  }
});
