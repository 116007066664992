import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { REACT_APP_LAUNCHDARKLEY_CLIENT_SIDE_ID } from './config';

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: REACT_APP_LAUNCHDARKLEY_CLIENT_SIDE_ID,
    options: {
      streaming: false
    },
    reactOptions: {
      useCamelCaseFlagKeys: false
    }
  });
  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
