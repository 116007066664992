import { ICheckoutActions } from '../actions';

interface ICheckoutReducerActions {
  SET_TOTAL: string;
  SET_TIP: string;
  SET_IS_CUSTOM_TIP: string;
  SET_USER_NAME: string;
  SET_USER_EMAIL: string;
  SET_USER_PHONE: string;
  ORDER_INSTRUCTION: string;
  SET_IRD_FUTURE_ORDER_STATUS: string;
  REMOVE_CHECKOUT_STATE: string;
  SET_TABLE_NUMBER: string;
  SET_HOUSE_ACCOUNT_STATUS: string;
  SET_DELIVERY_CHARGE: string;
  SET_SERVICE_CHARGE: string
}

export const actions: ICheckoutReducerActions = {
  SET_TOTAL: 'CHECKOUT:SET_TOTAL',
  SET_TIP: 'CHECKOUT:SET_TIP',
  SET_IS_CUSTOM_TIP: 'CHECKOUT:SET_IS_CUSTOM_TIP',
  SET_USER_NAME: 'CHECKOUT:SET_USER_NAME',
  SET_USER_EMAIL: 'CHECKOUT:SET_USER_EMAIL',
  SET_USER_PHONE: 'CHECKOUT:SET_USER_PHONE',
  SET_IRD_FUTURE_ORDER_STATUS: 'CHECKOUT:SET_IRD_FUTURE_ORDER_STATUS',
  ORDER_INSTRUCTION: 'CHECKOUT:ORDER_INSTRUCTION',
  REMOVE_CHECKOUT_STATE: 'CHECKOUT:REMOVE_CHECKOUT_STATE',
  SET_TABLE_NUMBER: 'CHECKOUT:SET_TABLE_NUMBER',
  SET_HOUSE_ACCOUNT_STATUS: 'CHECKOUT:SET_HOUSE_ACCOUNT_STATUS',
  SET_DELIVERY_CHARGE: 'CHECKOUT:SET_DELIVERY_CHARGE',
  SET_SERVICE_CHARGE: 'CHECKOUT:SET_SERVICE_CHARGE'
};

export interface ICheckoutData {
  allow_future_orders_ird?: boolean;
  total: number | string;
  tip: number | string;
  isCustomTip: number | string;
  user_name: string | number;
  user_email: string | number;
  user_phone: string | number;
  order_instruction: any;
  table_number?: number;
  house_account_status?: boolean;
  delivery_charge?: string | number;
  service_charges?: {}
}

export const defaultState: ICheckoutData = {
  total: 0,
  tip: 0,
  isCustomTip: 0,
  user_name: '',
  user_email: '',
  user_phone: '',
  order_instruction: '',
  table_number: undefined,
  delivery_charge: 0,
  service_charges: {}
};

export default (state: ICheckoutData = defaultState, { type, payload }: ICheckoutActions): ICheckoutData => {
  switch (type) {
    case actions.SET_TOTAL:
      return {
        ...state,
        total: payload
      };
    case actions.SET_TIP:
      return {
        ...state,
        tip: payload
      };
    case actions.SET_SERVICE_CHARGE:
      return {
        ...state,
        service_charges: payload
      };
    case actions.SET_IS_CUSTOM_TIP:
      return {
        ...state,
        isCustomTip: payload
      };
    case actions.SET_DELIVERY_CHARGE:
      return {
        ...state,
        delivery_charge: payload
      };
    case actions.SET_USER_NAME:
      return {
        ...state,
        user_name: payload
      };
    case actions.SET_HOUSE_ACCOUNT_STATUS:
      return {
        ...state,
        house_account_status: payload
      };
    case actions.SET_USER_EMAIL:
      return {
        ...state,
        user_email: payload
      };
    case actions.SET_USER_PHONE:
      return {
        ...state,
        user_phone: payload
      };
    case actions.ORDER_INSTRUCTION:
      return {
        ...state,
        order_instruction: payload
      };
    case actions.SET_TABLE_NUMBER:
      return {
        ...state,
        table_number: payload
      };
    case actions.SET_IRD_FUTURE_ORDER_STATUS:
      return {
        ...state,
        allow_future_orders_ird: payload
      };
    case actions.REMOVE_CHECKOUT_STATE:
      return {
        ...defaultState
      };
    default:
      return state;
  }
};
