import { createSelector } from "reselect";

import { ICartData } from "../reducers/cartReducer";
const cartSelector: any = (state: any) => state.cartData;

export const loadingSelector = () =>
  createSelector(cartSelector, (cartData: any) => cartData.loading);

export const dishCountSelector = () =>
  createSelector(
    cartSelector,
    (cartData: any) => Object.keys(cartData.cart).length
  );

export const selectedDishesSelector = () =>
  createSelector(cartSelector, (cartData: any) => cartData.cart);
  
export const selectedDishIDSelector = () =>
  createSelector(cartSelector, (cartData: any) => {
    const selItem: any = Object.values(cartData.cart)[0];
    return selItem?.dish.menuTabId;
  });

export const selectedMenuHoursSelector = () =>
  createSelector(cartSelector, (cartData: any) => {
    console.log("cartData>>", cartData);
    return cartData.menu_hours;
  });

export const selectedRestaurant = () =>
  createSelector(cartSelector, (cartData: any) => {
    const selItem: any = Object.values(cartData.cart)[0];
    return selItem?.restaurantName;
  });

export const checkPricingFieldsSelector = () =>
  createSelector(
    cartSelector,
    (cartData: any) => cartData.check_pricing_fields
  );

export const isNewAddressSelector = () =>
  createSelector(cartSelector, (cartData: any) => cartData.is_new_address);

export const deliveryAddressSelector = () =>
  createSelector(cartSelector, (cartData: any) => cartData.delivery_address);

export const userStateSelector = () =>
  createSelector(cartSelector, (cartData: any) => cartData.user_state);

export const userCitySelector = () =>
  createSelector(cartSelector, (cartData: any) => cartData.user_city);

export const userZipSelector = () =>
  createSelector(cartSelector, (cartData: any) => cartData.user_zip);

export const errorSelector = () =>
  createSelector(cartSelector, (cartData: any) => cartData.error);

export const selectedDishesAndModifiersSelector = createSelector(
  selectedDishesSelector,
  cartSelector,
  (selectedDishes, cartData: ICartData) =>
    Object.keys(cartData.cart).reduce(
      (acc: any, item) => {
        const cartItem = cartData?.cart[item];

        const { comment, quantity, modifiers } = cartItem;
        const { section_dish_id, easy_tab_id } = cartItem?.dish;

        const data: any = {
          items: [
            ...acc.items,
            {
              menu_item: easy_tab_id,
              instructions: comment,
              quantity,
              modifiers,
            },
          ],
        };

        if (cartData?.promoCode) data.promo_code_id = cartData?.promoCode;

        return data;
      },
      { items: [] }
    )
);

export const selectedDishesSumSelector = createSelector(
  selectedDishesSelector,
  cartSelector,
  (selectedDishes, cartData: ICartData) =>
    Object.keys(cartData.cart).reduce((acc, item) => {
      const cartItem = cartData?.cart[item];

      const selectedOptionsArray = Object.keys(cartItem.options);

      let optionsSumm = 0;
      if (selectedOptionsArray?.length) {
        optionsSumm = selectedOptionsArray.reduce(
          (acc: any, item) =>
            acc +
            (cartItem.options[item]?.isSelected
              ? +cartItem.options[item].price
              : 0),
          0
        );
      }

      return +parseFloat(
        (
          acc +
          (cartItem?.dish?.price * cartItem?.quantity + optionsSumm)
        ).toString()
      ).toPrecision(6);
    }, 0)
);
