// @flow
import React from 'react';
import './styles.scss';

interface ILoaderProps {
    className?: string
}

const Loader = ({ className = '' }: ILoaderProps) => (
  <div className="loading">
    <div className={`spinner flex fac fjc ${className}`}>
      <div className="bounce1" />
      <div className="bounce2" />
      <div className="bounce3" />
    </div>
  </div>
);

export default Loader;
