import { IRestaurantActions } from '../actions';
import { RestaurantHours } from '../../types/restaurantTypes';

interface IActions {
  SET_IS_ORDERING: string;
  SET_FOOTER_BG_COLOR: string;
  SET_RESTAURANT_HOURS: string;
  SET_ALLOW_FUTURE_ORDERS: string;
}

export const actions: IActions = {
  SET_IS_ORDERING: 'RESTAURANT:SET_IS_ORDERING',
  SET_FOOTER_BG_COLOR: 'RESTAURANT:SET_FOOTER_BG_COLOR',
  SET_RESTAURANT_HOURS: 'RESTAURANT:SET_RESTAURANT_HOURS',
  SET_ALLOW_FUTURE_ORDERS: 'RESTAURANT:SET_ALLOW_FUTURE_ORDERS'
};

export interface IRestaurantData {
  isOrdering: boolean,
  footerBgColor: string | undefined,
  hours: RestaurantHours | undefined,
  allow_future_orders: boolean | undefined
}

export const defaultState: IRestaurantData = {
  isOrdering: false,
  footerBgColor: undefined,
  hours: undefined,
  allow_future_orders: false
};

// eslint-disable-next-line max-len
export default (state: IRestaurantData = defaultState, { type, payload }: IRestaurantActions): IRestaurantData => {
  switch (type) {
    case actions.SET_IS_ORDERING:
      if (payload.menuList) {
        const isOrdering = payload.menuList.menus.some(menu => menu.is_ordering);

        return {
          ...state,
          isOrdering
        };
      }
      return {
        ...state
      };
    case actions.SET_FOOTER_BG_COLOR:
      return {
        ...state,
        footerBgColor: payload.bgColor || undefined
      };
    case actions.SET_ALLOW_FUTURE_ORDERS:
      return {
        ...state,
        allow_future_orders: payload.allow_future_orders
      };
    case actions.SET_RESTAURANT_HOURS:
      return {
        ...state,
        hours: payload.hours
      };
    default:
      return state;
  }
};
