import { actions } from '../reducers/cartReducer';
import { SelectedDish } from '../../types/common';
import { ICartActions } from './index';

export function add(selectedDish: SelectedDish): ICartActions {
  return { type: actions.ADD, payload: { selectedDish } };
}

export function addSucceed(dishInternalId: string, selectedDish: SelectedDish): ICartActions {
  return { type: actions.ADD_SUCCEED, payload: { dishInternalId, selectedDish } };
}

export function addFailed(msg: string): ICartActions {
  return { type: actions.ADD_FAILED, payload: { msg } };
}

export function init(restaurantName?: string): ICartActions {
  return { type: actions.INIT, payload: { restaurantName } };
}

export function initSucceed(selectedDishMaps: { [key: string]: SelectedDish }): ICartActions {
  return { type: actions.INIT_SUCCEED, payload: { selectedDishMaps } };
}

export function quantityChange(dishInternalId: string, quantity: number, restaurantName?: string): ICartActions {
  return { type: actions.QUANTITY_CHANGE, payload: { dishInternalId, quantity, restaurantName } };
}

export function selectedDishUpdate(
  dishInternalId: string, selectedDish: SelectedDish, restaurantName?: string
): ICartActions {
  return { type: actions.SELECTED_DISH_UPDATE, payload: { dishInternalId, selectedDish, restaurantName } };
}

export function selectedDishUpdateSucceed(
  dishInternalId: string, selectedDish: SelectedDish
): ICartActions {
  return { type: actions.SELECTED_DISH_UPDATE_SUCCEED, payload: { dishInternalId, selectedDish } };
}

export function setMenuHours(menu_hours: any[]): ICartActions {
  return { type: actions.MENU_HOURS, payload: { menu_hours } };
}

export function selectedDishDelete(dishInternalId: string, restaurantName?: string): ICartActions {
  return { type: actions.SELECTED_DISH_DELETE, payload: { dishInternalId, restaurantName } };
}

export function selectedDishDeleteSucceed(dishInternalId: string): ICartActions {
  return { type: actions.SELECTED_DISH_DELETE_SUCCEED, payload: { dishInternalId } };
}

export function tax(selectedDishesAndModifiers: any, restaurantName?: string) {
  return { type: actions.TAX, payload: { selectedDishesAndModifiers, restaurantName } };
}

export function checkPricingFieldsRequest(loading: boolean) {
  return { type: actions.CHECK_PRICING_FIELDS_REQUEST, payload: { loading } };
}

export function checkPricingFieldsSuccess(check_pricing_fields: { [key: string]: any }) {
  return { type: actions.CHECK_PRICING_FIELDS_SUCCESS, payload: { check_pricing_fields } };
}

export function promoCode(promoCode: any, restaurantName: string) {
  return { type: actions.PROMO_CODE, payload: { promoCode, restaurantName } };
}

export function promoCodeSucceed(promoCode: any) {
  return { type: actions.PROMO_CODE_SUCCEED, payload: { promoCode } };
}

export function promoCodeFailed(data: any) {
  return { type: actions.PROMO_CODE_FAILED, payload: data };
}

export function deliveryAddress(data: any) {
  return { type: actions.DELIVERY_ADDRESS, payload: data };
}

export function removeDeliveryAddress() {
  return { type: actions.REMOVE_DELIVERY_ADDRESS };
}

export function clearTheCart() {
  return { type: actions.CLEAR_CART };
}
