import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import createLogger from 'redux-logger';
import cartSaga from './sagas/cartSaga';

export default (reducer: any, middlewares = []) => {
  const initialState: any = {};
  const { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ }: any = window;
  const composeEnhancers = __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const sagaMiddleware = createSagaMiddleware();

  const switchMode = () => {
    if (process.env.NODE_ENV === 'development') {
      return [sagaMiddleware, createLogger, ...middlewares];
    }

    return [sagaMiddleware, ...middlewares];
  };

  const store = createStore(
    reducer,
    initialState,
    composeEnhancers(
      applyMiddleware(...switchMode())
    )
  );

  sagaMiddleware.run(cartSaga);

  return store;
};
