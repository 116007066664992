import { ICartActions, ErrorType } from "../actions";

interface ICartReducerActions {
  ADD: any;
  LOADING: string;
  ADD_SUCCEED: string;
  ADD_FAILED: string;

  INIT: any;
  INIT_SUCCEED: string;

  QUANTITY_CHANGE: any;
  QUANTITY_CHANGE_SUCCEED: string;
  QUANTITY_CHANGE_FAILED: string;

  SELECTED_DISH_UPDATE: any;
  SELECTED_DISH_UPDATE_SUCCEED: string;

  SELECTED_DISH_DELETE: any;
  SELECTED_DISH_DELETE_SUCCEED: string;

  TAX: string;
  CHECK_PRICING_FIELDS_REQUEST: string;
  CHECK_PRICING_FIELDS_SUCCESS: string;
  PROMO_CODE: string;
  PROMO_CODE_SUCCEED: string;
  PROMO_CODE_FAILED: string;
  DELIVERY_ADDRESS: string;
  REMOVE_DELIVERY_ADDRESS: string;
  MENU_HOURS: string;

  CLEAR_CART: any;
}

export const actions: ICartReducerActions = {
  ADD: "CART:ADD",
  LOADING: "CART:LOADING",
  ADD_SUCCEED: "CART:ADD_SUCCEED",
  ADD_FAILED: "CART:ADD_FAILED",

  INIT: "CART:INIT",
  INIT_SUCCEED: "CART:INIT_SUCCEED",

  QUANTITY_CHANGE: "CART:QUANTITY_CHANGE",
  QUANTITY_CHANGE_SUCCEED: "CART:QUANTITY_CHANGE_SUCCEED",
  QUANTITY_CHANGE_FAILED: "CART:QUANTITY_CHANGE_FAILED",

  SELECTED_DISH_UPDATE: "CART:SELECTED_DISH_UPDATE",
  SELECTED_DISH_UPDATE_SUCCEED: "CART:SELECTED_DISH_UPDATE_SUCCEED",

  SELECTED_DISH_DELETE: "CART:SELECTED_DISH_DELETE",
  SELECTED_DISH_DELETE_SUCCEED: "CART:SELECTED_DISH_DELETE_SUCCEED",

  TAX: "cart:TAX",
  CHECK_PRICING_FIELDS_REQUEST: "cart:CHECK_PRICING_FIELDS_REQUEST",
  CHECK_PRICING_FIELDS_SUCCESS: "cart:CHECK_PRICING_FIELDS",
  PROMO_CODE: "CART:PROMO_CODE",
  PROMO_CODE_SUCCEED: "CART:PROMO_CODE_SUCCEED",
  PROMO_CODE_FAILED: "CART:PROMO_CODE_FAILED",
  DELIVERY_ADDRESS: "CART:DELIVERY_ADDRESS",
  REMOVE_DELIVERY_ADDRESS: "CART:REMOVE_DELIVERY_ADDRESS",
  MENU_HOURS: "CART:MENU_HOURS",

  CLEAR_CART: "CART:CLEAR_CART",
};

export interface ICartData {
  loading?: boolean;
  cart: any;
  tax?: number;
  check_pricing_fields?: { [key: string]: any };
  promoCode: any;
  delivery_address?: string;
  user_state?: string;
  user_city?: string;
  user_zip?: string;
  is_new_address?: boolean;
  menu_hours: any;
  error: ErrorType | null;
}

export const defaultState: ICartData = {
  loading: true,
  cart: {},
  promoCode: null,
  delivery_address: "",
  is_new_address: false,
  tax: 0,
  check_pricing_fields: {},
  menu_hours: {},
  error: null,
};

export default (
  state: ICartData = defaultState,
  { type, payload }: ICartActions
): ICartData => {
  switch (type) {
    case actions.LOADING:
      return {
        ...state,
        loading: payload.loading,
      };
    case actions.ADD_SUCCEED:
      if (payload.dishInternalId) {
        return {
          ...state,
          cart: {
            ...state.cart,
            [payload.dishInternalId]: payload.selectedDish,
          },
        };
      }
      return {
        ...state,
      };
    case actions.INIT:
      return {
        ...state,
        loading: true,
      };
    case actions.INIT_SUCCEED:
      return {
        ...state,
        cart: {
          ...payload.selectedDishMaps,
        },
      };
    case actions.SELECTED_DISH_UPDATE:
      return {
        ...state,
        loading: true,
      };
    case actions.SELECTED_DISH_UPDATE_SUCCEED:
      if (payload.dishInternalId) {
        return {
          ...state,
          cart: {
            ...state.cart,
            [payload.dishInternalId]: payload.selectedDish,
          },
          loading: false,
        };
      }
      return state;
    case actions.SELECTED_DISH_DELETE_SUCCEED:
      if (payload.dishInternalId) {
        const { [payload.dishInternalId]: deletedDish, ...others } = state.cart;
        return {
          ...state,
          cart: {
            ...others,
          },
        };
      }
      return state;
    case actions.CHECK_PRICING_FIELDS_REQUEST:
      return {
        ...state,
        loading: payload?.loading,
      };
    case actions.CHECK_PRICING_FIELDS_SUCCESS:
      return {
        ...state,
        check_pricing_fields: payload?.check_pricing_fields,
        loading: false,
      };
    case actions.PROMO_CODE_SUCCEED:
      return {
        ...state,
        cart: {
          ...state.cart,
        },
        promoCode: payload?.promoCode,
        error: null,
      };
    case actions.PROMO_CODE_FAILED:
      return {
        ...state,
        error: payload as ErrorType,
      };
    case actions.REMOVE_DELIVERY_ADDRESS:
      return {
        ...state,
        delivery_address: "",
        user_state: "",
        user_city: "",
        user_zip: "",
        is_new_address: false,
      };
    case actions.DELIVERY_ADDRESS:
      return {
        ...state,
        delivery_address: payload?.delivery_address,
        user_state: payload?.user_state,
        user_city: payload?.user_city,
        user_zip: payload?.user_zip,
        is_new_address: payload?.is_new_address,
      };
    case actions.MENU_HOURS:
      return {
        ...state,
        menu_hours: payload.menu_hours,
      };
    case actions.CLEAR_CART:
      return {
        ...state,
        cart: {},
      };
    default:
      return state;
  }
};
