export const ACTION_STATUSES = {
  PENDING: 'pending',
  SUCCEED: 'successful',
  FAILED: 'failed',
  UNDEFINED: undefined
};

export const INPUT_STATUSES = {
  VALID: 'valid',
  INVALID: 'invalid',
  UNDEFINED: undefined
};

export const DEFAULT_ERROR = 'Something went wrong';

export const MENU_TYPES = {
  DEFAULT: 'default',
  OFFICIAL: 'official',
  STANDARD: 'standard',
  VISUAL: 'visual',
  ORDERING: 'ordering'
};

export const DAYS: { web: string, mobile: string }[] = [{
  web: 'Mon',
  mobile: 'Monday'
}, {
  web: 'Tue',
  mobile: 'Tuesday'
}, {
  web: 'Wed',
  mobile: 'Wednesday'
}, {
  web: 'Thu',
  mobile: 'Thursday'
}, {
  web: 'Fri',
  mobile: 'Friday'
}, {
  web: 'Sat',
  mobile: 'Saturday'
}, {
  web: 'Sun',
  mobile: 'Sunday'
}
];

export const NAV_BAR_MENU: { [key: string]: string } = {
  menu: 'menu',
  info: 'info'
};

export const ALREADY_VISITED: string = 'alreadyVisited';
export const GENERALL_ERROR: string = 'There seems to be a connection issue between us and the restaurant\'s point of sale. Please try again soon. We are working on this issue now.';

export const DIGIT_REG_EXP = /[0-9]/;
export const LETTERS_REG_EXP = /^[A-Za-z\s]+$/;
export const PHONE_NUMBER_REG_EXP = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
export const EMAIL_REG_EXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const CARD_HOLDER_NAME_REG_EXP = /^[a-z ,.'-]+$/i;
