/* eslint-disable max-len */
import { takeLatest, put, select } from 'redux-saga/effects';
import { actions } from '../reducers/cartReducer';
import { ICartActions } from '../actions';
import { selectedDishesAndModifiersSelector } from '../selectors/cartSelector';
import StorageManager from '../../utils/storageManager';
import { CustomError, sumToFixed } from '../../utils/helpers';
import { checkPricing as checkPricingRequest } from '../../utils/requests/ordering/checkPricing';
import { validatePromoCode } from '../../utils/requests/ordering/validatePromoCode';
import {
  addFailed,
  addSucceed,
  initSucceed,
  selectedDishDeleteSucceed,
  selectedDishUpdateSucceed,
  promoCodeSucceed,
  promoCodeFailed as promoCodeFailedAction,
  tax as taxAction,
  checkPricingFieldsRequest,
  checkPricingFieldsSuccess
} from '../actions/cartActions';
import { SelectedDish } from '../../types/common';
import { setServiceCharges } from '../actions/checkoutActions';

function* addHandler({ payload: { selectedDish } }: ICartActions) {
  try {
    if (!selectedDish) {
      return;
    }
    const { dishInternalId } = selectedDish;
    if (!dishInternalId) {
      return;
    }
    // eslint-disable-next-line camelcase
    const key = StorageManager.getCartKey(dishInternalId, selectedDish.restaurantName);
    if (!key) {
      return;
    }
    if (selectedDish) {
      StorageManager.setItem(key, selectedDish);

      // eslint-disable-next-line camelcase
      yield put(addSucceed(dishInternalId, selectedDish));
    }
  } catch (e) {
    console.error(e);
    yield put(addFailed('DB Error'));
  }
}

function* initHandler({ payload: { restaurantName } }: ICartActions) {
  try {
    const cartItems: SelectedDish[] = StorageManager.getAll(restaurantName);
    const selectedDishMaps: { [key: string]: SelectedDish } = {};
    for (const item of cartItems) {
      // eslint-disable-next-line camelcase
      const { dishInternalId } = item;
      if (dishInternalId) {
        selectedDishMaps[dishInternalId] = item;
      }
    }
    yield put(initSucceed(selectedDishMaps));
  } catch (e) {
    console.error(e);
  }
}

function* quantityChangeHandler({ payload: { dishInternalId, quantity, restaurantName } }: ICartActions) {
  try {
    if (!dishInternalId || !quantity) {
      return;
    }
    const key = StorageManager.getCartKey(dishInternalId, restaurantName);
    const selectedDish: SelectedDish = StorageManager.getItem(key);

    const newSelectedDish: SelectedDish = {
      ...selectedDish,
      quantity
    };
    StorageManager.setItem(key, newSelectedDish);
    yield put(selectedDishUpdateSucceed(dishInternalId, newSelectedDish));
  } catch (e) {
    console.error(e);
  }
}

function* selectedDishUpdateHandler({ payload: { dishInternalId, selectedDish, restaurantName } }: ICartActions) {
  try {
    if (!dishInternalId || !selectedDish) {
      return;
    }
    const key = StorageManager.getCartKey(dishInternalId, restaurantName);
    StorageManager.setItem(key, selectedDish);
    yield put(selectedDishUpdateSucceed(dishInternalId, selectedDish));
  } catch (e) {
    console.error(e);
  }
}

function* selectedDishDeleteHandler({ payload: { dishInternalId, restaurantName } }: ICartActions) {
  try {
    if (!dishInternalId) {
      return;
    }
    const key = StorageManager.getCartKey(dishInternalId, restaurantName);
    StorageManager.removeItem(key);

    yield put(selectedDishDeleteSucceed(dishInternalId));
  } catch (e) {
    console.error(e);
  }
}

function* promoCodeHandler({ payload: { promoCode, restaurantName } }: ICartActions) {
  try {
    const { easyTabRestName } = StorageManager.getRestaurantData(restaurantName);

    if (easyTabRestName) {
      const response = yield validatePromoCode(easyTabRestName, { coupon_name: promoCode });
      const { status, data } = response;

      if (status == 'Success') {
        yield put(promoCodeSucceed(data?.id));
        const selectedDishesAndModifiers = yield select(selectedDishesAndModifiersSelector);
        yield put(taxAction(selectedDishesAndModifiers, restaurantName));
      } else if (status == 'Error') {
        throw new CustomError({ name: 'couponError', message: response?.message });
      }
    }
  } catch (e) {
    yield put(promoCodeFailedAction({ name: e.name, message: e.message }));
  }
}

function* taxHandler({ payload: { selectedDishesAndModifiers, restaurantName } }: ICartActions) {
  try {
    const { easyTabRestName, orderType } = StorageManager.getRestaurantData(restaurantName);
    if (easyTabRestName) {
      yield put(checkPricingFieldsRequest(true));
      const response = yield checkPricingRequest(
        easyTabRestName, {
          orderType,
          ...selectedDishesAndModifiers
        }
      );
      const { status, data: { totals } } = response;
      if (status == 'Success') {
        const check_pricing_fields: { [key: string]: any } = {};
        if (totals.sub_total) check_pricing_fields.Subtotal = sumToFixed(totals.sub_total / 100);
        if (totals.tax) check_pricing_fields.Tax = sumToFixed(totals.tax / 100);
        if (totals.total) check_pricing_fields.Total = sumToFixed(totals.total / 100);
        
        if (Object.keys(check_pricing_fields).length) {
          yield put(checkPricingFieldsSuccess(check_pricing_fields));
        }
        const serviceCharges: { [key: string]: any } | null = {};
        if (totals.service_charges) {
          serviceCharges[totals.service_charges_key] = sumToFixed(totals.service_charges / 100);
        }
        yield put(setServiceCharges(serviceCharges));
      }
    }
  } catch (e) {
    console.error(e);
  }
}

function* cartSaga(): any {
  yield takeLatest(actions.ADD, addHandler);
  yield takeLatest(actions.INIT, initHandler);
  yield takeLatest(actions.QUANTITY_CHANGE, quantityChangeHandler);
  yield takeLatest(actions.SELECTED_DISH_DELETE, selectedDishDeleteHandler);
  yield takeLatest(actions.SELECTED_DISH_UPDATE, selectedDishUpdateHandler);
  yield takeLatest(actions.PROMO_CODE, promoCodeHandler);
  yield takeLatest(actions.TAX, taxHandler);
}

export default cartSaga;
