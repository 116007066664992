import { actions } from '../reducers/checkoutReducer';
import { ICheckoutActions } from './index';

export function setTotal(total: string | number): ICheckoutActions {
  return {
    type: actions.SET_TOTAL,
    payload: total
  };
}

export function setHouseAccountStatus(status: boolean | undefined): ICheckoutActions {
  return {
    type: actions.SET_HOUSE_ACCOUNT_STATUS,
    payload: status
  };
}

export function setTip(tip: string | number): ICheckoutActions {
  return {
    type: actions.SET_TIP,
    payload: tip
  };
}

export function setDeliveryCharge(deliveryCharge: string | number): ICheckoutActions {
  return {
    type: actions.SET_DELIVERY_CHARGE,
    payload: deliveryCharge
  };
}

export function setServiceCharges(serviceCharges: { [key: string]: any }): ICheckoutActions {
  return {
    type: actions.SET_SERVICE_CHARGE,
    payload: serviceCharges
  };
}

export function setIsCustomTip(isCustomTip: number): ICheckoutActions {
  return {
    type: actions.SET_IS_CUSTOM_TIP,
    payload: isCustomTip
  };
}

export function setUserName(user_name: string): ICheckoutActions {
  return {
    type: actions.SET_USER_NAME,
    payload: user_name
  };
}

export function setUserEmail(user_email: string): ICheckoutActions {
  return {
    type: actions.SET_USER_EMAIL,
    payload: user_email
  };
}

export function setIrdFutureOrderStatus(status: boolean | undefined): ICheckoutActions {
  return {
    type: actions.SET_IRD_FUTURE_ORDER_STATUS,
    payload: status
  };
}

export function setUserPhone(user_phone: string): ICheckoutActions {
  return {
    type: actions.SET_USER_PHONE,
    payload: user_phone
  };
}

export function orderInstruction(order_instruction: any): ICheckoutActions {
  return {
    type: actions.ORDER_INSTRUCTION,
    payload: order_instruction
  };
}

export function removeCheckoutState() {
  return {
    type: actions.REMOVE_CHECKOUT_STATE
  };
}

export function setTableNumber(value: number) {
  return {
    type: actions.SET_TABLE_NUMBER,
    payload: value
  };
}
