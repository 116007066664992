import React from 'react';
import { ToastContainer } from 'react-toastify';
import { GENERALL_ERROR } from 'src/utils/constants';

export default function ErrorFallBack() {
  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      >
        {GENERALL_ERROR}
      </ToastContainer>
    </div>
  );
}
