import { CART_STORAGE_PREFIX } from "../../config";

class StorageManager {
  static setItem = (key: string, data: any, isSessionStorage = false) => {
    try {
      const serializedState = JSON.stringify(data);
      !isSessionStorage
        ? localStorage.setItem(key, serializedState)
        : sessionStorage.setItem(key, serializedState);
    } catch {
      // ignore write errors
    }
  };

  static getItem = (key: string, isSessionStorage = false) => {
    try {
      const serializedState = !isSessionStorage
        ? localStorage.getItem(key)
        : sessionStorage.getItem(key);
      if (serializedState === null) {
        return;
      }
      // eslint-disable-next-line consistent-return
      return JSON.parse(serializedState);
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  static setRestaurantData = (
    key: string,
    data: any,
    restaurantSlug?: string,
    isSessionStorage?: boolean
  ) => {
    try {
      const restaurantData = StorageManager.getItem(
        `${restaurantSlug}`,
        isSessionStorage
      );

      if (!restaurantData) {
        StorageManager.setItem(
          `${restaurantSlug}`,
          { [key]: data },
          isSessionStorage
        );
      } else {
        restaurantData[key] = data;

        StorageManager.setItem(
          `${restaurantSlug}`,
          restaurantData,
          isSessionStorage
        );
      }
    } catch (err) {}
  };

  static getRestaurantData = (
    restaurantSlug?: string,
    isSessionStorage?: boolean
  ) => {
    try {
      return StorageManager.getItem(`${restaurantSlug}`, isSessionStorage);
    } catch (err) {}
  };

  static removeItem = (key: string, isSessionStorage = false) => {
    !isSessionStorage
      ? localStorage.removeItem(key)
      : sessionStorage.removeItem(key);
  };

  static getAll = (prefixKey?: string) => {
    const values = [];
    const storageKeys = Object.keys(localStorage);
    for (const key of storageKeys) {
      if (prefixKey && key.startsWith(prefixKey)) {
        values.push(StorageManager.getItem(key));
      }
    }
    return values;
  };

  static setDeliveryAddressInfo = (
    delivery_address: string,
    user_state: string,
    user_city: string,
    user_zip: number,
    restaurant_slug?: string
  ) => {
    StorageManager.setRestaurantData(
      "deliveryAddress",
      {
        address: delivery_address,
        user_state,
        user_city,
        user_zip,
      },
      restaurant_slug,
      true
    );
  };

  static getDeliveryInfoByKey = (
    key: string,
    restaurant_slug?: string,
    isSessionStorage?: boolean
  ) => {
    const deliveryAddress = StorageManager.getRestaurantData(
      restaurant_slug,
      isSessionStorage
    )?.deliveryAddress;

    return deliveryAddress ? deliveryAddress[key] : null;
  };

  static removeDeliveryAddressInfo = (restaurant_slug?: string) => {
    StorageManager.removeItem(`${restaurant_slug}`, true);
  };

  static removeAll = (prefixKey?: string) => {
    if (localStorage.length) {
      for (let i = 0; i < localStorage.length; i++) {
        if ((localStorage as any).key(i).indexOf(prefixKey) != -1) {
          const key = localStorage.key(i);
          if (key) {
            localStorage.removeItem(key);
            i--;
          }
        }
      }
    }
  };

  static getStorageKey = (
    prefix: string,
    suffix: string,
    restaurantName?: string
  ) => `${restaurantName}__${prefix}${suffix}`;

  static getCartKey = (key: string, restaurantName?: string) => {
    return StorageManager.getStorageKey(
      key,
      CART_STORAGE_PREFIX,
      restaurantName
    );
  };
}

export default StorageManager;
