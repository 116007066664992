// @flow
import React, { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import './styles.scss';
import { isHomePage, isResyPage } from '../../utils/helpers';
import StorageManager from '../../utils/storageManager';

const logoDesktopImage: any = require('../../common/assets/images/logo-desktop.svg');
const logoMobileImage: any = require('../../common/assets/images/logo-mobile.svg');

interface IFooterProps {
  restaurantName?: string;
  bgColor?: string | undefined;
  loading?: boolean;
}

function Footer({
  restaurantName,
  bgColor,
  loading = true
}: IFooterProps): JSX.Element | null {
  const location = useLocation();
  const isResy = isResyPage(location);
  const isHome = isHomePage(location);

  const footerColor = bgColor || StorageManager.getRestaurantData(restaurantName)?.footerColor;
  if (isHome) {
    return null;
  }

  if (isResy) {
    return (
      <footer className="home" style={{ backgroundColor: footerColor || '#063B72' }}>
        <section className="bottom flex fdr-reverse fjb">
          <div>
            <ul className="menu">
              <li className="menu-item">
                <Link
                  target="_blank"
                  to="//https://www.winendine.com/terms/"
                  title="Terms of Use"
                >
                  Terms of Use
                </Link>
              </li>
              <li className="menu-item">
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  to="//https://www.winendine.com/privacy/"
                  title="Privacy Policy"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <img src={logoDesktopImage} alt="wine and dine logo" />
            <span className="copyright">&copy; Wine n Dine All rights reserved.</span>
          </div>
        </section>
      </footer>
    );
  }

  if (loading) {
    return null;
  }

  return (
    <footer style={{ backgroundColor: footerColor || '#063B72' }}>
      <div className="simple flex fac fjc">
        <span>Powered by</span>
        <Link to="/">
          <img className="desktop" alt="wine-n-dine logo" src={logoDesktopImage} />
          <img className="mobile" alt="wine-n-dine logo" src={logoMobileImage} />
        </Link>
      </div>
    </footer>
  );
}

export default memo(Footer);
