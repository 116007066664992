import { AxiosResponse } from "axios";

import { apiRequest } from "./index";

export const checkPricing = async (
  easyTabRestName: string,
  data: { items: Array<Object>; orderType: string }
) => {
  const body = {
    items: data.items,
    ordering_type: data.orderType,
  };

  let suffix = "check_pricing";
  if (data.orderType === "ivo_to") {
    suffix = "check_pricing_table_ordering";
    // delete body.ordering_type
  }

  const response: AxiosResponse = await apiRequest.post(
    `/ordering/${easyTabRestName}/${suffix}`,
    body
  );

  return response.data;
};
