import { combineReducers } from 'redux';
import cartReducer from './cartReducer';
import checkoutReducer from './checkoutReducer';
import restaurantReducer from './restaurantReducer';

export default combineReducers({
  cartData: cartReducer,
  checkoutData: checkoutReducer,
  restaurantData: restaurantReducer
});
