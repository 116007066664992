/* eslint-disable react/jsx-props-no-spreading */

import React, { Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ErrorFallback from 'src/common/components/ErrorFallback';
import Footer from './Footer';
import Loader from '../common/components/Loader';
import { ROUTES } from './routes';
import { initializeGA } from '../utils/initializeGA';

interface Containers {}

function Containers({}: Containers) {
  useEffect(() => {
    initializeGA();
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="app">
        <Suspense fallback={<Loader />}>
          <Switch>
            {ROUTES.map((route) => (
              <Route
                key={route.key}
                exact={route.exact}
                path={`${route.path}`}
                component={(props: any) => (
                  <route.component
                    {...props}
                    menuType={route.key}
                    ivoTo={route.ivoTo}
                    qr={route.qr}
                    ird={route.ird}
                    ivo={route.ivo}
                    catering={route.catering}
                  />
                )}
              />
            ))}
          </Switch>
          <ToastContainer
            className="toast-container"
            position="top-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
          />
        </Suspense>
        <Footer />
      </div>
    </ErrorBoundary>
  );
}

export default Containers;
